<!--
 * @Description: 拍照位置管理
 * @Author: ChenXueLin
 * @Date: 2021-10-15 15:34:13
 * @LastEditTime: 2022-10-14 18:11:27
 * @LastEditors: ChenXueLin
-->

<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="photoPositionName">
              <el-input
                v-model="searchForm.photoPositionName"
                placeholder="拍照位置名称"
                title="拍照位置名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="sceneType">
              <e6-vr-select
                v-model="searchForm.sceneType"
                :data="sceneTypeList"
                placeholder="场景类型"
                title="场景类型"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="isenable">
              <e6-vr-select
                v-model="searchForm.isenable"
                :data="statusList"
                placeholder="状态"
                title="状态"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="thirdClassName">
              <el-input
                v-model="searchForm.thirdClassName"
                placeholder="商品名称"
                title="商品名称"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-add_line" title="新增" @click="handleAdd"></i>
          <i
            class="e6-icon-check_line1"
            title="启用"
            @click="changeStatus(1)"
          ></i>
          <i
            class="e6-icon-prohibit_line"
            title="禁用"
            @click="changeStatus(0)"
          ></i>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          :row-key="
            row => {
              return row.photoPositionId;
            }
          "
          ref="elTable"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="50"></el-table-column>
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
            <template slot-scope="{ row }">
              <span v-if="column.fieldName === 'fileName'">
                <e6-image-viewer
                  v-show="row.showViewer"
                  :on-close="
                    () => {
                      closeViewer(row);
                    }
                  "
                  :url-list="row.fileUrl ? [row.fileUrl] : []"
                >
                </e6-image-viewer>
                <el-button type="text" @click="handleToggle(row)">
                  {{ row.fileName }}
                </el-button>
              </span>
              <span v-else>{{ row[column.fieldName] }}</span>
            </template>
          </el-table-column>
          <el-table-column fixed="right" align="center" label="操作" width="80">
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList()"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
      <el-dialog
        v-dialogDrag
        title="添加拍照位置"
        :visible.sync="addVisible"
        width="600px"
        :before-close="handleClose"
        :close-on-click-modal="false"
        v-loading="dialogLoading"
        :element-loading-background="loadingBackground"
        custom-class="custom-dialog"
      >
        <el-form
          ref="addForm"
          label-width="120px"
          :model="addForm"
          :rules="addFormRules"
        >
          <el-form-item label="拍照位置名称" prop="photoPositionName">
            <el-input
              v-model.trim="addForm.photoPositionName"
              placeholder="拍照位置名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="拍照类型" prop="pictureType">
            <e6-vr-select
              v-model="addForm.pictureType"
              :data="pictureTypeList"
              placeholder="拍照类型"
              title="拍照类型"
              clearable
              :props="{
                id: 'codeValue',
                label: 'codeName'
              }"
            ></e6-vr-select>
          </el-form-item>
          <el-form-item label="场景类型" prop="sceneType">
            <e6-vr-select
              v-model="addForm.sceneType"
              :data="sceneTypeList"
              placeholder="场景类型"
              title="场景类型"
              clearable
              :props="{
                id: 'codeValue',
                label: 'codeName'
              }"
            ></e6-vr-select>
          </el-form-item>
          <el-form-item label="商品分类" prop="firstClassId">
            <e6-vr-select
              v-model="addForm.firstClassId"
              :data="firstClassList"
              placeholder="商品分类"
              title="商品分类"
              clearable
              :props="{
                id: 'firstClassId',
                label: 'firstClassName'
              }"
            ></e6-vr-select>
          </el-form-item>
          <el-form-item label="商品开票名称" prop="secondClassId">
            <e6-vr-select
              v-model="addForm.secondClassId"
              :data="secondClassList"
              placeholder="商品开票名称"
              title="商品开票名称"
              clearable
              :props="{
                id: 'secondClassId',
                label: 'secondClassName'
              }"
            ></e6-vr-select>
          </el-form-item>
          <el-form-item label="商品名称" prop="thirdClassId">
            <e6-vr-select
              v-model="addForm.thirdClassId"
              :data="thirdClassList"
              placeholder="商品名称"
              title="商品名称"
              clearable
              :props="{
                id: 'thirdClassId',
                label: 'thirdClassName'
              }"
            ></e6-vr-select>
          </el-form-item>

          <el-form-item label="预览图" prop="fileId">
            <uploadOBS
              class="avatar-uploader"
              v-model="addForm.fileId"
              :fileType="11"
              :limitSize="2"
              :showFileList="false"
              :limitConfig="['jpeg', 'png', 'bmp', 'gif', 'jpg']"
              buttonText="点击上传"
              uploadTip="注：图片大小不超过2M，仅可上传一张"
              @success="handleSuccess"
              :showBtnType="0"
            >
              <div slot="preview">
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </div>
            </uploadOBS>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input
              v-model.trim="addForm.remark"
              placeholder="备注"
              maxlength="100"
            ></el-input>
          </el-form-item>
        </el-form>
        <div class="dialog-footer" slot="footer">
          <el-button class="cancel" @click="handleClose">取消</el-button>
          <el-button type="primary" @click="toAdd">确定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import uploadOBS from "@/components/uploadOBS/uploadOBS";
import listPageReszie from "@/mixins/list-page-resize";
import {
  getPhotoLocation,
  getFirstClassList,
  disablePhotoLocation,
  findDownList,
  enablePhotoLocation,
  createPhotoLocation,
  updatePhotoLocation,
  getThirdClassList,
  getSecondClassList,
  downFile
} from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "photoLocation",
  components: { uploadOBS },
  data() {
    return {
      total: 0,
      searchForm: {
        photoPositionName: "",
        sceneType: "",
        thirdClassName: "",
        isenable: "",
        pageIndex: 1,
        pageSize: 20
      },
      uploadFileList: [],
      sceneTypeList: [], //场景类型下拉框
      statusList: [
        { id: 1, label: "启用" },
        { id: 0, label: "禁用" }
      ], //状态
      columnData: [
        {
          fieldName: "photoPositionId",
          display: true,
          fieldLabel: "拍照位置ID",
          width: 120,
          disabled: false,
          fixed: true,
          align: "center"
        },
        {
          fieldName: "photoPositionName",
          display: true,
          fieldLabel: "拍照位置名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "sceneTypeName",
          display: true,
          fieldLabel: "场景类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "pictureTypeName",
          display: true,
          fieldLabel: "拍照类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "firstClassName",
          display: true,
          fieldLabel: "商品分类",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "secondClassName",
          display: true,
          fieldLabel: "商品开票名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "thirdClassName",
          display: true,
          fieldLabel: "商品名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "fileName",
          display: true,
          fieldLabel: "预览图",
          width: 200,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "isenableName",
          display: true,
          fieldLabel: "状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "remark",
          display: true,
          fieldLabel: "备注",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createUserName",
          display: true,
          fieldLabel: "创建人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdTime",
          display: true,
          fieldLabel: "创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [],
      selColumnId: [],
      /*****新增*****/
      pictureTypeList: [], //拍照类型下拉框
      firstClassList: [], //商品分类下拉框
      secondClassList: [], //商品开票名称
      thirdClassList: [], //商品名称
      addVisible: false,
      imageUrl: "",
      addForm: {
        fileId: "",
        firstClassId: "",
        photoPositionName: "",
        pictureType: "",
        sceneType: "",
        remark: "",
        secondClassId: "",
        thirdClassId: ""
      },
      addFormRules: {
        photoPositionName: [
          {
            required: true,
            message: "请输入拍照位置名称",
            trigger: ["blur", "change"]
          }
        ],
        pictureType: [
          {
            required: true,
            message: "请选择拍照类型",
            trigger: ["blur", "change"]
          }
        ],
        sceneType: [
          {
            required: true,
            message: "请选择场景类型",
            trigger: ["blur", "change"]
          }
        ]
      },
      dialogLoading: false
    };
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {
    "addForm.firstClassId": {
      immediate: true,
      handler(val) {
        if (val) {
          this.getSecondClassList();
        } else {
          this.secondClassList = [];
          this.addForm.secondClassId = "";
          this.thirdClassList = [];
          this.addForm.thirdClassId = "";
        }
      }
    },
    "addForm.secondClassId": {
      immediate: true,
      handler(val) {
        if (val) {
          this.getThirdClassList();
        } else {
          this.thirdClassList = [];
          this.addForm.thirdClassId = "";
        }
      }
    }
  },
  created() {
    this.initData();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    async initData() {
      try {
        this.selectLoading = true;
        let promiseList = [
          findDownList(["sceneType", "pictureType"]),
          getFirstClassList()
        ];
        let [res, firstRes] = await Promise.all(promiseList);
        //场景类型下拉框
        this.sceneTypeList = this.getFreezeResponse(res, {
          path: "data.sceneType"
        });
        //拍照类型下拉框
        this.pictureTypeList = this.getFreezeResponse(res, {
          path: "data.pictureType"
        });
        //设备分类下拉框
        this.firstClassList = this.getFreezeResponse(firstRes, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    //查询故障列表
    async queryList() {
      try {
        this.loading = true;
        let res = await getPhotoLocation(this.searchForm);
        let tableData = res.data.array;
        tableData.map(item => {
          item.showViewer = false;
        });
        this.tableData = tableData;
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
        this.$nextTick(() => {
          this.$refs.elTable.doLayout();
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //预览图片
    handleToggle(row) {
      row.showViewer = true;
    },
    //关闭预览
    closeViewer(row) {
      row.showViewer = false;
    },
    // 表格勾选时间
    handleSelectionChange(columns) {
      this.selColumnId = columns.map(item => {
        return Object.assign(
          {},
          {
            id: item.photoPositionId,
            versionNumber: item.versionNumber
          }
        );
      });
    },
    //启用、禁用
    changeStatus(type) {
      let tip, title;
      if (type == 1) {
        tip = "是否确认拍照位置";
        title = "启用拍照位置";
      } else {
        tip = "是否确认禁用拍照位置";
        title = "禁用拍照位置";
      }
      this.$confirm(tip, title, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          if (type == 1) {
            this.enableErrorReq();
          } else {
            this.disableErrorReq();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    //启用请求
    async enableErrorReq() {
      try {
        this.loading = true;
        let res = await enablePhotoLocation({ idList: this.selColumnId });
        if (res.code == "OK") {
          this.$message.success("启用成功");
          this.selColumnId = [];
          this.$refs.elTable.clearSelection();
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //禁用请求
    async disableErrorReq() {
      try {
        this.loading = true;
        let res = await disablePhotoLocation({ idList: this.selColumnId });
        if (res.code == "OK") {
          this.$message.success("禁用成功");
          this.selColumnId = [];
          this.$refs.elTable.clearSelection();
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "编辑",
          id: 1
        }
      ];
      return operates;
    },
    // 操作--更多
    handleOperate(val, row) {
      if (val.id == 1) {
        this.addVisible = true;
        this.isAdd = false;
        this.addForm.photoPositionName = _.cloneDeep(row.photoPositionName)
          ? _.cloneDeep(row.photoPositionName)
          : "";
        this.addForm.firstClassId = _.cloneDeep(row.firstClassId)
          ? _.cloneDeep(row.firstClassId)
          : "";
        this.addForm.secondClassId = _.cloneDeep(row.secondClassId)
          ? _.cloneDeep(row.secondClassId)
          : "";
        this.addForm.thirdClassId = _.cloneDeep(row.thirdClassId)
          ? _.cloneDeep(row.thirdClassId)
          : "";
        this.addForm.sceneType = _.cloneDeep(row.sceneType)
          ? _.cloneDeep(row.sceneType).toString()
          : "";
        this.addForm.pictureType = _.cloneDeep(row.pictureType)
          ? _.cloneDeep(row.pictureType).toString()
          : "";
        this.addForm.photoPositionId = _.cloneDeep(row.photoPositionId);
        this.addForm.fileId = _.cloneDeep(row.fileId)
          ? _.cloneDeep(row.fileId)
          : "";
        this.addForm.remark = _.cloneDeep(row.remark);
        this.imageUrl = _.cloneDeep(row.fileUrl);
        this.addForm.versionNumber = _.cloneDeep(row.versionNumber);
      }
    },
    /*********新增**********/
    //获取二级分类下拉框
    async getSecondClassList() {
      try {
        this.dialogLoading = true;
        let res = await getSecondClassList({
          equipClassId: this.addForm.firstClassId
        });
        this.secondClassList = res.data;
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //获取三级分类下拉框
    async getThirdClassList() {
      try {
        this.dialogLoading = true;
        let res = await getThirdClassList([this.addForm.secondClassId]);
        this.thirdClassList = res.data[this.addForm.secondClassId];
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //添加安装位置
    handleAdd() {
      this.addVisible = true;
      this.isAdd = true;
    },
    handleUpdate() {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          if (this.isAdd) {
            this.createReq();
          } else {
            this.updateReq();
          }
        }
      });
    },
    // 上传成功后的回调
    handleSuccess(res) {
      if (res.code === "OK") {
        // 获取图片地址
        this.downFile(res.data[0].fileId);
      }
    },
    // 获取文件地址
    async downFile(id) {
      try {
        let res = await downFile({ id });
        this.imageUrl = res.data.fileUrl;
        console.log(this.imageUrl);
      } catch (error) {
        printError(error);
      }
    },
    toAdd() {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          if (this.isAdd) {
            this.createReq();
          } else {
            this.updateReq();
          }
        }
      });
    },
    //新增请求
    async createReq() {
      try {
        this.dialogLoading = true;
        let res = await createPhotoLocation(this.addForm);
        if (res.code == "OK") {
          this.$message.success("创建成功");
          this.handleClose();
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //修改请求
    async updateReq() {
      try {
        this.selectLoading = true;
        let res = await updatePhotoLocation(this.addForm);
        if (res.code == "OK") {
          this.$message.success("修改成功");
          this.handleClose();
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.selectLoading = false;
      }
    },
    //关闭弹框
    handleClose() {
      this.addForm = {
        fileId: "",
        firstClassId: "",
        photoPositionName: "",
        pictureType: "",
        sceneType: "",
        remark: "",
        secondClassId: "",
        thirdClassId: ""
      };
      this.imageUrl = "";
      this.$refs.addForm.resetFields();
      this.addVisible = false;
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.custom-dialog {
  .el-dialog__body {
    padding: 20px 18px 2px 18px;
    .el-form-item {
      .el-input {
        width: 350px;
      }
    }
    .avatar-uploader {
      .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }
      .el-upload:hover {
        border-color: #409eff;
      }
      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 150px;
        height: 150px;
        line-height: 150px;
        text-align: center;
      }
      .avatar {
        width: 150px;
        height: 150px;
        display: block;
      }
    }
  }
}
</style>
